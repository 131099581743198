<template>
  <div style="width: 100%;">
    <slot v-bind="{ on }">
      <v-btn
        text
        class="black--text"
        style="border: 1px solid #aaa"
        @click="onClick"
      >
        {{ '작업 재등록' | translate }}
      </v-btn>
    </slot>
  </div>
</template>

<script>
    import VuexProps from "@/framework/vuex/VuexProps";

    export default {
        name: "AsCloneButton",
        mixins: [VuexProps],
        props: {
            item: null,
            replace: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            on() {
                return {
                    click: this.onClick,
                }
            },
        },
        methods: {
            onClick() {
                this.$emit("click:button");
                if (this.replace) {
                    this.routerManager.replace({name: "AsReRegister", params: {workorderId: this.item.raw.workorderId,}});
                } else {
                    this.routerManager.push({name: "AsReRegister", params: {workorderId: this.item.raw.workorderId,}});
                }
            },
        },
    }
</script>

<style scoped>

</style>