<template>
  <div style="width: 100%;">
    <slot v-bind="{ on }">
      <v-btn
        color="accent-4"
        style="color: #EC4DA3; border: 1px solid #EC4DA3"
        text
        @click="onClick"
      >
        {{ '취소' | translate }}
      </v-btn>
    </slot>
  </div>
</template>

<script>
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "AsCancelDialogWithModal",
        components: {},
        mixins: [ModalVuetifyUser],
        props: {
            item: {
                type: Object,
                default: undefined
            },
            options: {
                type: Object, default: () => {
                    return {}
                }
            },
            callback: {
                type: Function,
                default: undefined,
            },
        },
        data() {
            return {}
        },
        computed: {
            on() {
                return {
                    click: this.onClick,
                }
            }
        },
        methods: {
            onClick() {
                this.$emit("click:button");
                this.openModalVuetify(MODAL_VUETIFY_NAMES.AS.CANCEL, {
                        fullscreen: true,
                        transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
                    }, {item: this.item},
                    (data) => {
                        this.$emit("click:cancel", data);
                        if (this.callback) this.callback(data);
                    }, MODAL_VUETIFY_NAMES.AS.CANCEL + "-" + this.item.raw.workorderId,
                );
            },
        },
    }
</script>

<style scoped>

</style>
