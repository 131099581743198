<template>
  <v-btn
    text
    style="border: 1px solid #aaa !important;"
    @click="onClick"
  >
    {{ '거절' | translate }}
  </v-btn>
</template>

<script>

    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "AsDenyDialogWithModal",
        components: {},
        mixins: [ModalVuetifyUser],
        props: {
            item: {
                type: Object,
                default: undefined
            },
            options: {
                type: Object, default: () => {
                    return {}
                }
            },
        },
        data() {
            return {}
        },
        methods: {
            onClick() {
                this.openModalVuetify(MODAL_VUETIFY_NAMES.AS.DENY, {
                        fullscreen: true,
                        transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
                    }, {},
                    (data) => {
                      this.$emit('click:deny', data);
                    }, MODAL_VUETIFY_NAMES.AS.DENY + "-" + this.item.raw.workorderId,
                );
            },
        }
    }
</script>

<style scoped>
    >>> .v-toolbar__content, .v-toolbar__extension {
        width: 100%;
        justify-content: space-between;
    }

    >>> .v-toolbar__items {
        flex: 1;
        justify-content: center;
    }

    >>> .v-text-field--outlined fieldset {
        border: 1px solid #ddd !important;
    }

    >>> .v-text-field--outlined {
        border-radius: 0px;
    }

    >>> .v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: calc(100vh - 135px) !important;
        max-height: calc(100vh - 135px) !important;
    }
</style>
