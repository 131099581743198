<template>
  <div style="width: 100%;">
    <slot v-bind="{ on }">
      <v-btn
        text
        color="accent-4 border__aaa"
        @click="onAcceptClicked"
      >
        {{ '수락' | translate }}
      </v-btn>
    </slot>
  </div>
</template>

<script>
    export default {
        name: "AsAcceptButton",
        props: {
            callback: {
                type: Function,
                default: undefined,
            },
        },
        computed: {
            on() {
                return {
                    click: this.onAcceptClicked,
                }
            }
        },
        methods: {
            onAcceptClicked() {
                this.$emit("click:button");
                this.$dialog()
                    .message(this.$translate("수락하시겠습니까?"))
                    .buttonPositive(this.$translate("수락"), () => {
                        this.$emit("click:accept");
                        if (this.callback) this.callback();
                    })
                    .buttonNegative(this.$translate("닫기"))
                    .build()
                    .show();
            },
        },
    }
</script>

<style scoped>
    .v-btn:not(.v-btn--round).v-size--default {
        border: 1px solid #aaa !important;
    }
</style>