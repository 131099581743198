<template>
  <v-card
    class="mx-auto boder-bottom"
    flat
    style="border-radius: 0px; position: relative;"
    width="100%"
  >
    <v-list-item
      v-ripple="false"
      class="text-left"
      style="padding: 14px 18px !important;"
      @click="onDetailClicked(item)"
    >
      <v-list-item-content class="pa-0">
        <v-list-item-title class="d-flex justify-space-between mb-2">
          <!-- 업체명 -->
          <div
            class="text-left font__16 black--text"
            style="white-space: pre-wrap !important; line-height: 25px !important;"
          >
            <span>{{ item.raw.accountName }} [{{ item.raw.accountId }}]</span>
          </div>

          <!-- 진행상태 (ex:기사대기) -->
          <div>
            <span
              class="font_16 color__blue"
              style="line-height: 25px !important;"
            >{{ t(item.raw.workorderStateName) || '' }}</span>
          </div>
        </v-list-item-title>


        <v-list-item-subtitle class="font__14 black--text">
          <div
            class="font__17 font-weight-bold"
            style="white-space: pre-wrap !important;"
          >
            <span>{{ item.raw.address }} {{ item.raw.addressDetail }}</span>
          </div>

          <div
            class="sub-item"
          >
            {{ item.raw.branchName }}
          </div>

          <!-- 프린터 모델명 -->
          <div
            class="sub-item"
          >
            {{ item.raw.printerModelName }}
          </div>

          <div
            v-if="item.raw.workorderReceiveType !== '0' && item.raw.workorderReceiveType !== null && !!CODE.WORKORDER_RECEIVE_TYPE_NAME[item.raw.workorderReceiveType]"
            class="sub-item font-weight-bold"
            style="color: #00aaaa"
          >
            {{ CODE.WORKORDER_RECEIVE_TYPE_NAME[item.raw.workorderReceiveType] }}
          </div>

          <div class="sub-item">
            {{ 'A/S기사' | translate }} : {{ item.raw.staffName }}
          </div>

          <div class="sub-item">
            {{ '접수일시' | translate }} : {{ item.raw.workorderReceiptDate | datetime }}
          </div>
          <div class="sub-item">
            {{ '예약일시' | translate }} : {{ item.raw.reservationDatetime | datetime }}
          </div>
          <div
            v-if="item.isWorkorderStateCanceled"
            class="sub-item"
          >
            {{ '취소일시' | translate }} : {{ item.raw.doneDate | datetime }}
          </div>
          <div v-if="item.isWorkorderStateCompleted">
            {{ '완료일시' | translate }} : {{ item.raw.doneDate | datetime }}
          </div>

          <!-- 미수내역 -->
          <v-list-item-subtitle v-if="item.raw.sumBalance > 0">
            <div class="caption d-flex justify-space-between">
              <ul class="pa-0">
                <li
                  v-show="item.raw.sumBalance > 0"
                  class="color__red font__14"
                >
                  {{ '미수금' | translate }} :
                  {{ item.raw.outstandingMonths }}{{ '개월' | translate }} /
                  {{ item.raw.sumBalance | number }}{{ '원' | translate }}
                </li>
                <li
                  v-show="item.raw.sumBalance <= 0"
                  style="visibility: hidden;"
                />
              </ul>
            </div>
          </v-list-item-subtitle>

          <div class="d-flex justify-space-between align-center">
            <!-- 증상 -->
            <div class="color__blue">
              {{ item.raw.reasonCodeName }}
            </div>

            <!--<v-btn
                                                                          icon
                                                                          width="22px"
                                                                          height="22px"
                                                                        >
                                                                          <img
                                                                            src="@/assets/images/main/svg/button_More.svg"
                                                                          >
                                                                        </v-btn>-->
            <context-menu
              :location="{lat:item.raw.lat, lng:item.raw.lng}"
              :tel-no="item.raw.customerMobileno"
            />
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <!-- 관리자용 액션 카드입니다. -->
    <template v-if="user.isAdmin">
      <as-action-with-card
        :item="item"
        :options="options"
        class="pa-0"
        @click:accept="() => item.accept().catch()"
        @click:assign="(staffId) => item.assign(staffId).catch()"
        @click:cancel="(v) => item.cancel(v).catch()"
        @click:complete="(editData) => item.complete(editData).catch()"
        @click:delegate="(staffId) => item.delegate(staffId).catch()"
        @click:deny="(v) => item.deny(v).catch()"
        @click:reserve="(data) => item.reserve(data).catch()"
        @click:reserveWithProcess="(data) => item.reserveWithProcess(data).catch()"
        @click:assignWithInformation="(data) => item.assignWithInformation(data).catch()"
      />
    </template>

    <!-- 작업자용 액션 카드입니다. -->
    <template v-else-if="user.isWorker">
      <as-action-with-card-for-worker
        :item="item"
        :options="options"
        class="pa-0"
        @click:accept="() => item.accept().catch()"
        @click:assign="(staffId) => item.assign(staffId).catch()"
        @click:cancel="(v) => item.cancel(v).catch()"
        @click:complete="(editData) => item.complete(editData).catch()"
        @click:delegate="(staffId) => item.delegate(staffId).catch()"
        @click:deny="(v) => item.deny(v).catch()"
        @click:reserve="(data) => item.reserve(data).catch()"
        @click:reserveWithProcess="(data) => item.reserveWithProcess(data).catch()"
        @click:assignWithInformation="(data) => item.assignWithInformation(data).catch()"
      />
    </template>
  </v-card>
</template>

<script>
    import AsActionWithCard from "@/components/items/as/AsActionWithCard";
    import VuexProps from "@/framework/vuex/VuexProps";
    import AsActionWithCardForWorker from "@/components/items/as/AsActionWithCardForWorker";
    import ContextMenu from "@/components/widgets/ContextMenu";

    export default {
        name: "AsItemCard",
        components: {ContextMenu, AsActionWithCardForWorker, AsActionWithCard},
        mixins: [VuexProps],
        props: {
            item: {type: Object, default: undefined},
            options: {
                type: Object, default: () => {
                    return {}
                }
            },
        },
        computed: {},
        methods: {
            /**
             * 상세 버튼 클릭시
             * @param work
             */
            onDetailClicked(item) {
                this.routerManager.push({name: "AsDetail",
                  params: {
                    workorderId: item.raw.workorderId,
                  }});
            },

        }
    }
</script>

<style scoped>
    >>> .v-list-item__content {
        padding: 12px 0;
    }

    >>> .v-btn:not(.v-btn--round).v-size--default {
        max-width: 100% !important;
        width: 100% !important;
    }

    .sub-item {
        color: #666
    }
</style>
