<template>
  <div
    style="padding: 0 10px 10px !important;"
  >
    <v-card-actions>
      <!-- 접수 상태 상태시 -->
      <template v-if="received">
        <v-row>
          <as-accept-button @click:accept="$emit('click:accept')" />
        </v-row>
      </template>

      <!-- 기사대기 상태시 -->
      <template v-else-if="watingStaff">
        <!-- 작업자 본인일 경우 -->
        <template v-if="itsMe">
          <v-row class="justify-space-between">
            <!-- 예약 -->
            <v-col class="pa-0">
              <as-reserve-with-process-modal
                :options="options"
                :item="item"
                @click:reserveWithProcess="data => $emit('click:reserveWithProcess', data)"
              />
            </v-col>
            <!-- 배정취소 -->
            <v-col class="pa-0 ml-2">
              <as-cancel-assign-button
                :item="item"
                @click:assignWithInformation="data => $emit('click:assignWithInformation', data)"
              />
            </v-col>
            <!-- 위임 -->
            <v-col class="pa-0 ml-2">
              <staff-delegate-dialog-with-modal
                :exclude="item.raw.worker"
                :options="options"
                @click:delegate="staffId => $emit('click:delegate', staffId)"
              />
            </v-col>
            <!-- 수락 -->
            <v-col class="pa-0 ml-2">
              <as-accept-button @click:accept="$emit('click:accept')" />
            </v-col>
          </v-row>
          <v-row class="mt-2 justify-space-between">
            <!-- 취소 -->
            <v-col class="pa-0">
              <as-cancel-dialog-with-modal
                :options="options"
                :item="item"
                @click:cancel="v => $emit('click:cancel', v)"
              />
            </v-col>
            <!-- 완료 -->
            <v-col class="pa-0 ml-2">
              <as-success-dialog-with-modal
                :item="item"
                :options="options"
                @click:complete="editData => $emit('click:complete', editData)"
              />
            </v-col>
          </v-row>
        </template>

        <!-- 본인이 아닌 다른 작업자일 경우 -->
        <template v-else />
      </template>

      <!-- 진행 상태시 -->
      <template v-else-if="inProgress">
        <!-- 작업자 본인일 경우 -->
        <template v-if="itsMe">
          <v-row class="justify-space-between">
            <!-- 예약 -->
            <v-col class="pa-0">
              <as-reserve-dialog-with-modal
                :options="options"
                :item="item"
                @click:reserve="data => $emit('click:reserve', data)"
              />
            </v-col>
            <!-- 배정취소 -->
            <v-col class="pa-0 ml-2">
              <as-cancel-assign-button
                :item="item"
                @click:assignWithInformation="data => $emit('click:assignWithInformation', data)"
              />
            </v-col>
            <!-- 위임 -->
            <v-col
              class="pa-0 ml-2"
            >
              <staff-delegate-dialog-with-modal
                :exclude="item.raw.worker"
                :options="options"
                @click:delegate="staffId => $emit('click:delegate', staffId)"
              />
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <!-- 취소 -->
            <v-col
              class="pa-0"
            >
              <as-cancel-dialog-with-modal
                :options="options"
                :item="item"
                @click:cancel="v => $emit('click:cancel', v)"
              />
            </v-col>
            <!-- 완료 -->
            <v-col
              class="pa-0 ml-2"
            >
              <as-success-dialog-with-modal
                :item="item"
                :options="options"
                @click:complete="editData => $emit('click:complete', editData)"
              />
            </v-col>
          </v-row>
        </template>

        <!-- 본인이 아닌 다른 작업자일 경우 -->
        <template v-else />
      </template>

      <!-- 완료 상태시 -->
      <template v-else-if="item.isWorkorderStateCanceled">
        <v-row>
          <as-clone-button :item="item" />
        </v-row>
      </template>
      <!-- 취소 상태시 -->
      <template v-else-if="item.isWorkorderStateCompleted">
        <v-row>
          <as-clone-button :item="item" />
        </v-row>
      </template>
    </v-card-actions>
  </div>
</template>

<script>
    import {CODE} from "@/constants";
    import AsAcceptButton from "@/components/part/as/AsAcceptButton";
    import AsCloneButton from "@/components/part/as/AsCloneButton";
    import AsCancelDialogWithModal from "@/components/part/as/button-with-modal/AsCancelDialogWithModal";
    import AsDenyDialogWithModal from "@/components/part/as/button-with-modal/AsDenyDialogWithModal";
    import AsReserveWithProcessModal from "@/components/part/as/button-with-modal/AsReserveWithProcessModal";
    import AsReserveDialogWithModal from "@/components/part/as/button-with-modal/AsReserveDialogWithModal";
    import AsSuccessDialogWithModal from "@/components/part/as/button-with-modal/AsSuccessDialogWithModal";
    import StaffDelegateDialogWithModal from "@/components/part/as/button-with-modal/StaffDelegateDialogWithModal";
    import AsCancelAssignButton from "@/components/part/as/AsCancelAssignButton";

    export default {
        name: "AsActionWithCardForWorker",
        components: {
          StaffDelegateDialogWithModal,
          AsSuccessDialogWithModal,
          AsReserveDialogWithModal,
          AsReserveWithProcessModal,
            AsCancelDialogWithModal,
            AsCloneButton,
            AsAcceptButton,
          AsCancelAssignButton,
        },
        props: {
            item: {
                type: Object,
                default: undefined
            },
            options: {
                type: Object, default: () => {
                }
            },
        },
        computed: {
            received() {
                return this.item.raw.workorderState === CODE.AS.RECEIVED;
            },
            watingStaff() {
                return this.item.raw.workorderState === CODE.AS.WAITING_STAFF;
            },
            inProgress() {
                return this.item.raw.workorderState === CODE.AS.IN_PROGRESS;
            },
            itsMe() {
                return this.item.raw.worker === this.user.userId;
            },
        },
        created() {
            console.log();
        },
    }
</script>

<style scoped>
    >>> .v-btn {
        height: 45px !important;
        width: 100% !important;
        padding: 0 16px;
        border-radius: 0 !important;
    }

    >>> .v-btn:not(.v-btn--round).v-size--default {
        height: 36px;
        width: 100% !important;
        padding: 0 16px;
        box-sizing: border-box;
        border: 1px solid #ddd;
    }

    >>> .v-application .red--text.text--accent-4 {
        color: #EC4DA3 !important;
        caret-color: #EC4DA3 !important;
    }

    >>> .v-card__actions {
        display: block;
    }
</style>