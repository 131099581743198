<template>
  <div style="width: 100%;">
    <slot v-bind="{ on }">
      <v-btn
        text
        style="border: 1px solid #aaa !important;"
        @click="onClick"
      >
        {{ '위임' | translate }}
      </v-btn>
    </slot>
  </div>
</template>

<script>
    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "StaffDelegateDialogWithModal",
        components: {},
        mixins: [ModalVuetifyUser],
        props: {
            exclude: null,
            options: {
                type: Object, default: () => {
                    return {}
                }
            },
            callback: {
                type: Function,
                default: undefined,
            },
        },
        data() {
            return {}
        },
        computed: {
            on() {
                return {
                    click: this.onClick,
                }
            },
        },
        methods: {
            onClick() {
                this.$emit("click:button");
                this.openModalVuetify(MODAL_VUETIFY_NAMES.AS.DELEGATE, {
                        fullscreen: true,
                        transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
                    }, {exclude: this.exclude, options: this.options,},
                    (data) => {
                        this.$emit('click:delegate', data);
                        if (this.callback) this.callback(data);
                    }, MODAL_VUETIFY_NAMES.AS.DELEGATE + "-" + this.user.userId,
                );
            },
        }
    }
</script>

<style scoped>
    >>> .v-toolbar__content, .v-toolbar__extension {
        width: 100%;
        justify-content: space-between;
    }

    >>> .v-toolbar__items {
        flex: 1;
        justify-content: center;
    }

    >>> .v-text-field--outlined fieldset {
        border: 1px solid #ddd !important;
    }

    >>> .v-text-field--outlined {
        border-radius: 0px;
    }

    >>> .v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: calc(100vh - 135px) !important;
        max-height: calc(100vh - 135px) !important;
    }
</style>
