<script>
    import BaseListener from "@/assets/plugins/mps-common/event/base-listener";
    import {ON_WORKORDER_STATUS_UPDATED} from "@/constants/listeners";

    /**
     * 작업자 현황이 업데이트 되었을 때 호출됩니다.
     */
    export default {
        name: "OnWorkorderStatusUpdatedListener",
        extends: BaseListener,
        created() {
            this.registerListener(ON_WORKORDER_STATUS_UPDATED, this.onWorkorderStatusUpdated);
        },
        beforeDestroy() {
            this.unregisterListener(ON_WORKORDER_STATUS_UPDATED, this.onWorkorderStatusUpdated);
        }
    }
</script>
