<template>
  <div style="width: 100%;">
    <slot v-bind="{ on }">
      <v-btn
        text
        color="accent-4 border__aaa"
        @click="onAssignWithInformationClicked"
      >
        {{ '배정취소' | translate }}
      </v-btn>
    </slot>
  </div>
</template>

<script>
    import {CODE} from "@/constants";

    export default {
        name: "AsCancelAssignButton",
        props: {
            item: {
              type: Object,
              default: undefined
            },
            callback: {
                type: Function,
                default: undefined,
            },
        },
        data() {
          return {
            editItem: {}
          }
        },
        computed: {
            on() {
                return {
                    click: this.onAssignWithInformationClicked,
                }
            }
        },
        created() {
            this.editItem = this.item
        },
        methods: {
          onAssignWithInformationClicked() {
                this.$emit("click:button");
                this.$dialog()
                    .message(this.$translate("배정취소 하시겠습니까?"))
                    .buttonPositive(this.$translate("확인"), () => {
                        this.editItem.workorderState = CODE.AS.RECEIVED;
                        this.$emit("click:assignWithInformation", this.editItem);
                        if (this.callback) this.callback(this.editItem);
                    })
                    .buttonNegative(this.$translate("닫기"))
                    .build()
                    .show();
            },
        },
    }
</script>

<style scoped>
    .v-btn:not(.v-btn--round).v-size--default {
        border: 1px solid #aaa !important;
    }

    >>> .v-btn__content {
      width: 100%;
      white-space: normal;
    }
</style>