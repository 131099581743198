<template>
  <div style="height: 100%; overflow-y: hidden;">
    <!-- 인피니트 리스트 -->
    <vue-infinite-loader
      :items="itemsOverwrite"
      :fetch="fetch"
      :identifier="identifier"
      item-key="raw.workorderId"
      :loading="loading"
      @update:inner-list="v => innerList = v"
    >
      <template #default="{ item }">
        <as-item-card
          :item="item"
          :options="asOptions"
        />
      </template>
    </vue-infinite-loader>

    <!-- 추가 버튼 -->
    <add-button
      v-if="addButton"
      style="position: fixed; left: 10px; bottom: 25px;"
      @click:button="onAddClicked"
    />

    <!-- 새로고침 버튼 -->
    <refresh-button
      :container="mounted ? $refs.container : undefined"
      :no-bottom="noBottom"
      @click="onRefresh"
    />
  </div>
</template>

<script>
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import {CODE, PATH} from "@/constants";
import Work from "@/model/work";
import {NATIVE_EVENT} from "@/assets/plugins/mps-mobile-native-bridge/native-bridge";
import OnFilterResetListener from "@/framework/listeners/OnFilterResetListener";
import RefreshButton from "@/components/comp/RefreshButton";
import AsItemCard from "@/components/items/as/AsItemCard";
import CodeOptions from "@/components/mixins/CodeOptions";
import OnMainSearchTriggered from "@/event/OnMainSearchTriggered";
import OnMainFilterTriggered from "@/event/OnMainFilterTriggered";
import OnWorkorderStatusUpdatedListener from "@/framework/listeners/OnWorkorderStatusUpdatedListener";
import AddButton from "@/components/comp/AddButton";

export default {
  name: "AsMainWorkorderList",
  components: {AddButton, AsItemCard, RefreshButton},
  extends: WindowComponent,
  mixins: [OnMainSearchTriggered, OnMainFilterTriggered, OnFilterResetListener, CodeOptions, OnWorkorderStatusUpdatedListener],
  props: {
    staffId: undefined,
    noBottom: {
      type: Boolean,
      default: false,
    },
    addButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      itemsOverwrite: [],
      searchOptions: {
        asReceiptTypeList: [],
        endDate: null,
        isReservation: false,
        isTodayOrAfter: null,
        reasonCodeList: [],
        startDate: null,
        workorderReceiveTypeList: [],
        workorderStateDayList: [],
        workorderStateList: []
      },
      identifier: 0,
      innerList: [],
      mounted: false,
      loading: false,
      loadKey: 0,
    };
  },
  unmounted() {
    this.$nativeEventBus.$off(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, this.loadOverwrite);
  },
  mounted() {
    this.initSearchOptions();
    this.loadAllUpToCurrent();
    this.$nativeEventBus.$on(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, this.loadOverwrite);
    this.loadOptions();
    this.mounted = true;
  },
  methods: {
    /**
     * 추가 버튼이 눌러졌습니다.
     */
    onAddClicked() {
      this.routerManager.push({name: "AsRegister", params: {staffId: this.staffId}});
    },

    onWorkorderStatusUpdated() {
      this.loadAllUpToCurrent(true);
    },

    /**
     * 목록을 불러와 현재 페이징된 아이템에 덮어씁니다.
     */
    loadOverwrite() {
      this.loadAllUpToCurrent(true);
    },

    loadOptions() {
      setTimeout(() => {
        this.loadOptionsForWorkorderStaff();
        this.loadOptionsForDoneReasonCode()
      }, 500);
    },

    /**
     * 목록을 불러옵니다.
     */
    async loadAllUpToCurrent(overwrite = false) {
      const loadKey = ++this.loadKey;
      this.loading = true;
      const response = await this.$request(PATH.MAP_APP.GET_ALL_WORKER_WORK_NEW)
        .setObject({...this.searchOptions, staffUId: this.staffId, mobileYn: 'Y'})
        .wait(500)
        .enqueue();
      this.loading = false;
      if (loadKey !== this.loadKey) return;
      const mapped = response.data.map(e => new Work(e));
      const sorted = mapped.sort((a, b) => {
        return a.raw.workSequence - b.raw.workSequence;
      });

      this.items.splice(0, this.items.length)
      this.items.push(...sorted);
      this.identifier++;

      // if (overwrite) {
      //     this.itemsOverwrite = sorted.splice(0, this.innerList.length + 3); // 추가된 작업이 일을 수 있기에 값을 조정하여 줍니다.
      // } else {
      //     this.items = sorted;
      //     this.identifier++;
      // }
    },


    /**
     * 불러온 목록에서 무한 페이징에 사용할 목록만 반환합니다.
     */
    fetch() {
      if (this.$isEmpty(this.items)) return [];
      return this.items.splice(0, 10);
    },

    /**
     * 검색키워드와 필터 조건을 초기화 합니다.
     */
    initSearchOptions() {
      this.searchOptions.endDate = null
      this.searchOptions.isReservation = false
      this.searchOptions.isTodayOrAfter = null
      this.searchOptions.startDate = null
      this.searchOptions.reasonCodeList.splice(0, this.searchOptions.reasonCodeList.length)
      this.searchOptions.asReceiptTypeList.splice(0, this.searchOptions.asReceiptTypeList.length)
      this.searchOptions.workorderReceiveTypeList.splice(0, this.searchOptions.workorderReceiveTypeList.length)
      this.searchOptions.workorderStateDayList.splice(0, this.searchOptions.workorderStateDayList.length)
      this.searchOptions.workorderStateList.splice(0, this.searchOptions.workorderStateList.length)

      this.searchOptions.workorderStateList.push(CODE.AS.WAITING_STAFF)// 기사대기, 진행
      this.searchOptions.workorderStateList.push(CODE.AS.IN_PROGRESS)
    },

    /**
     * 툴바 검색 버튼이 눌렸습니다.
     */
    onMainSearchTriggered(keyword) {
      this.searchOptions.searchKeyword = keyword;
      this.loadAllUpToCurrent();
    },

    /**
     * 필터 적용이 되었습니다.
     */
    onMainFilterTriggered(state) {
      this.searchOptions.endDate = state?.endDate || null
      this.searchOptions.isReservation = state?.isReservation || false
      this.searchOptions.isTodayOrAfter = state?.isTodayOrAfter || null
      this.searchOptions.startDate = state?.startDate || null

      if (!!state.reasonCodeList && state.reasonCodeList.length > 0){
        this.searchOptions.reasonCodeList.splice(0, this.searchOptions.reasonCodeList.length)
        this.searchOptions.reasonCodeList.push(...state.reasonCodeList)
      }

      if (!!state.asReceiptTypeList && state.asReceiptTypeList.length > 0){
        this.searchOptions.asReceiptTypeList.splice(0, this.searchOptions.asReceiptTypeList.length)
        this.searchOptions.asReceiptTypeList.push(...state.asReceiptTypeList)
      }

      if (!!state.workorderReceiveTypeList && state.workorderReceiveTypeList.length > 0){
        this.searchOptions.workorderReceiveTypeList.splice(0, this.searchOptions.workorderReceiveTypeList.length)
        this.searchOptions.workorderReceiveTypeList.push(...state.workorderReceiveTypeList)
      }


      /**
       * AS 진행상태가 선택되었으면 request 요청에 추가한다.
       */
      if (!!state.workorderStateList && state.workorderStateList.length > 0) {
        this.searchOptions.workorderStateDayList.splice(0, this.searchOptions.workorderStateDayList.length)
        this.searchOptions.workorderStateList.splice(0, this.searchOptions.workorderStateList.length)

        if (state.workorderStateList.includes(CODE.AS.WAITING_STAFF)) {
          this.searchOptions.workorderStateList.push(CODE.AS.WAITING_STAFF);
        }

        if (state.workorderStateList.includes(CODE.AS.IN_PROGRESS)) {
          this.searchOptions.workorderStateList.push(CODE.AS.IN_PROGRESS);
        }

        if (state.workorderStateList.includes(CODE.AS.CANCELED)) {
          this.searchOptions.workorderStateDayList.push(CODE.AS.CANCELED);
        }

        if (state.workorderStateList.includes(CODE.AS.COMPLETED)) {
          this.searchOptions.workorderStateDayList.push(CODE.AS.COMPLETED);
        }
      }
      this.loadAllUpToCurrent();
    },

    /**
     * 필터 조건을 초기화하고 리로드합니다.
     */
    onFilterReset() {
      this.initSearchOptions();
      this.loadAllUpToCurrent();
    },

    /**
     * 새로고침 버튼을 클릭하였습니다.
     *
     * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
     */
    onRefresh() {
      this.loadAllUpToCurrent();
    },
  }
}
</script>
